<template>
  <div class="container">

    <div class="floats_right">
      <a @click="()=>{
        contentHtml = textInfo.rule
        contentHtmlModal = true
      }"><img :src="oss+'2022112416391.png'"/></a>
      <router-link to="/service"><img :src="oss+'20221124163008.png'"/></router-link>
    </div>

    <div class="click_area_chunk" @click="onPoint"></div>
    <div class="click_area_chunk2" @click="onPoint"></div>
    <div class="paybtn" @click="onPoint" v-if="!paymentModal"><img :src="oss+'202211281852111.png'" width="100%"/></div>


    <van-swipe style="height:40px;" vertical :autoplay="3000" :show-indicators="false" class="logsWrap">
      <van-swipe-item v-for="(item,key) in logsList" :key="key">
        <van-row class="item" type="flex" align="center" justify="space-between">
          <van-col class="avatar"><img :src="(item.avatar=='' || item.avatar==null)?noheadimg:item.avatar" class="headimg"/><span>{{item.name}} {{item.time}}</span></van-col>
          <van-col class="desc">{{item.desc}}</van-col>
        </van-row>
      </van-swipe-item>
    </van-swipe>

    <div class="foot">
      <p>客服电话：4006178158</p>
      <p>{{textInfo.company_name}}</p>
    </div>

    <div class="loading_chunk" v-if="loadingchunk"><img :src="oss+'fried.gif'" class="bling" /></div>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#e15ae8" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>

    <van-popup v-if="paymentModal" v-model="paymentModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="paymentPop">
      <div class="row">
        <div class="head"><img :src="oss+'202212071725.png'" width="80%"/></div>
        <div class="shade_box">
          <div class="good"><img :src="oss+'iphone.jpg'" width="100%"/></div>
          <div class="content_text">
            <div class="hint_text_1">好运价：9.9元</div>
            <div class="hint_text_2">您将获得：塔罗测试报告&抽iPhone14机会</div>
          </div>

          <div class="btn" @click="onsubmit"><img :src="oss+'2022113011201.png'" width="100%"/></div>
          <div class="payList">
            <van-radio-group v-model="json.pay_way">
              <van-cell-group :border="false">
                <van-cell v-for="item in payList" :key="item.key">
                  <template #icon><img :src="item.img" height="20"/></template>
                  <template #title>
                    <van-row type="flex" align="center" :gutter="2">
                      <van-col>{{item.name}}</van-col>
                      <!--<van-col><van-tag plain type="primary" color="#e8323c" text-color="#e8323c">首单随机立减，最高至免单</van-tag></van-col>-->
                    </van-row>
                  </template>
                  <template #right-icon>
                    <van-radio :name="item.payWay" checked-color="#07c160" icon-size="18px"></van-radio>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
        </div>
        <div class="close"><van-icon name="close" color="#fff" size="24" @click="closeModal('redpacket1')"/></div>
      </div>

    </van-popup>

    <van-popup v-if="redpacket1" v-model="redpacket1" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" class="redpacket">
      <div class="shade_box_1">
        <div class="h4">恭喜获得红包</div>
        <div class="content_text"><span>3.00</span>元</div>
        <div class="hint_text_1">可减价查看塔罗解惑报告</div>
        <van-count-down millisecond :time="time" class="count-down">
          <template #default="timeData">
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds }}</span>
            <span class="colon">:</span>
            <span class="block">{{moment(timeData.milliseconds).format("SS")}}</span>
            <span class="colon">后失效</span>
          </template>
        </van-count-down>
        <div class="btn1" @click="onsubmit"><img :src="oss+'WechatIMG284.jpeg'" width="100%"/></div>
        <div class="btn2 mt10"><van-button plain round type="primary" color="#d49c46" block @click="closeModal('redpacket2')">残忍拒绝</van-button></div>
      </div>
    </van-popup>

    <van-popup v-if="redpacket2" v-model="redpacket2" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" class="redpacket">
      <div class="shade_box_2" @click.stop="()=>{ redpacket2 = false}">
        <div class="shade_box_img">
          <div class="h4">恭喜大额红包！</div>
          <div class="content_text"><span>4.00</span>元</div>
          <div class="hint_text_1">查看塔罗解析<span>仅需5.9元</span></div>
          <div class="hint_text_2">错过没有了哟</div>
          <div class="btn1" @click="onsubmit"><img :src="oss+'WechatIMG294.jpeg'" width="100%"/></div>
          <div class="btn2"><van-button plain round type="primary" color="#d49c46" block @click="()=>{}">浏览更多</van-button></div>
        </div>

      </div>
    </van-popup>

    <van-popup v-if="payCeateModal" v-model="payCeateModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" class="payCeatePop"  @click-overlay="()=>{
      this.clearTime()
    }">

      <div class="title">离领取iPhone14只差1步</div>
      <div class="title mt5">现在支付 即可查看</div>
      <div class="img_bg_box">
        <div class="only_remain_text">仅剩0.7%</div>
        <van-row type="flex" justify="space-between">
          <van-col class="bottom_text_1">信息提交</van-col>
          <van-col class="bottom_text_2">生成报告</van-col>
          <van-col class="">查看塔罗解析</van-col>
        </van-row>
      </div>

      <div class="btn1"><van-button block round type="primary" color="#cf031a" @click="onsubmit">立即支付</van-button></div>
      <div class="btn2"><van-button plain round type="primary" color="#f5a625" block @click="()=>{
        this.finishPay(this.json.order_sn)
      }">已完成支付</van-button></div>

      <van-count-down millisecond :time="time" class="count-down">
        <template #default="timeData">
          <span class="block">{{ timeData.minutes }}</span>
          <span class="colon">:</span>
          <span class="block">{{ timeData.seconds }}</span>
          <span class="colon">:</span>
          <span class="block">{{moment(timeData.milliseconds).format("SS")}}</span>
          <span class="colon">后失效</span>
        </template>
      </van-count-down>

    </van-popup>
  </div>
</template>
<script>
  import moment from 'moment'
  import {isWeixin,getordernum} from '@/utils/utils'
  import {initCloud} from '@wxcloud/cloud-sdk'
  import {GetPayConfig,GetUserPrivate,CreateOrder,GetOrderStatus} from './services/services'
  export default {
    name:'tarot_activity',
    components: {},
    data () {
      return {
        noheadimg:this.oss+'header.png',
        textInfo:{},
        time:1000*60*10,
        logsList:[],
        payList:[],
        optionHover:{
          step:0.4,
          limitMoveNum:2,
          hoverStop:false,
          direction:1,
          openWatch:true,
          singleHeight:20,
          singleWidth:0,
          waitTime:3000,
        },
        json:{
          id:0,
          pay_way:0,
          order_sn:'',
          phone:'',
          account:'',
          url:location.href
        },
        detail:{},
        contentHtmlModal:false,
        contentHtml:'',
        paymentModal:false,
        redpacket1:false,
        redpacket2:false,
        timer:null,
        loadingchunk:false,
        flag:true,
        payCeateModal:false
      }
    },
    created(){
      const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }
      this.isWeixin = isWeixin()?1:0
      // this.isWeixin = 1
      if(this.isWeixin){
        this.json.pay_way = 14
      }
      this.type = this.json.type==undefined ? undefined:this.json.type
    },
    mounted(){
      if(sessionStorage.getItem('order_info')){
        let order_info = JSON.parse(sessionStorage.getItem('order_info'))
        this.json={
          ...this.json,
          ...order_info
        }

        this.findOrderStatus(this.json.order_sn)
      }
      if(this.json.order_sn){
        this.findOrderStatus(this.json.order_sn)
      }

      this.findpayConfig()
      this.finduserprivate()
      this.findlogList()
    },
    watch:{
      // 'json.phone':{
      //   handler:function(value){
      //     if(value.length == 11){
      //       if(!/^1[3456789]\d{9}$/.test(value)) {
      //         this.$toast('请输入正确的手机号码')
      //         this.$refs.phone.focus()
      //       }else{
      //         this.$refs.phone.blur()
      //       }
      //     }
      //   },
      //   deep:true
      // }
    },
    methods:{
      moment,
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            this.detail = result.data.data
            this.payList=result.data.data.payConfig
            let payWay = this.payList[0].payWay
            let pay=this.payList.find(item=>item.payWay == payWay)

            const {pwyAccount} = pay
            this.json.pay_way=payWay
            this.json.account=pwyAccount

            let money=this.detail.goodsArray.find(item=>item.total_price == 200 && item.status==1)
            this.json.id=money.id

            const {app_download_url,kefu_url} = this.detail
            let data = {app_download_url,kefu_url}
            sessionStorage.setItem('payConfig',JSON.stringify(data))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      finduserprivate(){
        GetUserPrivate().then(result => {
          if(result.data.code==1){
            this.textInfo = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      findlogList(){
        this.logsList=[
          {avatar:'',name:'一**油1',time:'2分钟前',desc:'参与了塔罗牌抽iPhone手机活动'},
          {avatar:'',name:'一**油1',time:'2分钟前',desc:'参与了塔罗牌抽iPhone手机活动'},
          {avatar:'',name:'一**油1',time:'2分钟前',desc:'参与了塔罗牌抽iPhone手机活动'},
          {avatar:'',name:'一**油1',time:'2分钟前',desc:'参与了塔罗牌抽iPhone手机活动'},
          {avatar:'',name:'一**油1',time:'2分钟前',desc:'参与了塔罗牌抽iPhone手机活动'},
        ]
      },
      closeModal(key){
        this.flag = true
        this.paymentModal = false
        this.redpacket1 = false
        this.redpacket2 = false
        this[key]=true
      },
      onPoint(){
        if(this.flag){
          this.flag = false
          this.loadingchunk = true
          setTimeout(()=>{
            this.paymentModal = true
            this.loadingchunk = false
          },0.75 * 1000)
        }
      },
      onsubmit(){

        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })

        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        let json={
          ...this.json,
          order_sn:order_sn
        }
        this.getCreateOrder(json)
      },
      getCreateOrder(res){
        CreateOrder({...res,openid:(this.isWeixin?this.json.openid:undefined),pay_tag:((this.isWeixin || res.pay_way==14)?'wechat':'alipay')}).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem('order_info',JSON.stringify(res))
            const {data} = result.data
            let pay_way = res.pay_way
            if(pay_way==14){
              location.href=data
              // this.payWeChat(data)
            }else if(pay_way==16){
              this.Alipay(data)
            }

          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      async payWeChat(orderId){
        let c1 = new this.initCloud().Cloud({
          // 必填，表示是未登录模式
          identityless: true,
          // 资源方 AppID
          resourceAppid: 'wxdada9bc45300a359',
          // 资源方环境 ID
          resourceEnv: 'cloud1-3gua9rkm875bd364',
        })

        await c1.init()
        const res =  await c1.callFunction({
          name: 'public',
          data: {
            action: 'getUrlScheme',
            path:'pages/index/index', // 想跳转的小程序路径
            orderId:orderId, // 预下单单号
          },
        }).then((res)=>{
          let result = res.result
          if(result.errCode==0){
            location.href = result.openlink
          }
        })
      },
      Alipay(res){
        const div = document.createElement("formdiv")
        div.innerHTML = res
        document.body.appendChild(div)
        document.forms["pay"].setAttribute("target", "_self")
        document.forms["pay"].submit()
        div.remove()
      },
      findOrderStatus(order_sn){
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = code
            clearInterval(this.timer)
            this.$router.push({path:'/success'})
          }else{
            if(!this.payCeateModal){
              this.payCeateModal = true
            }
            // this.$dialog.confirm({
            //   title: '确认支付情况',
            //   confirmButtonText:'已完成支付',
            //   cancelButtonText:'重新支付',
            //   closeOnClickOverlay:true
            // }).then(() => {
            //   this.finishPay(order_sn)
            // }).catch((res) => {
            //   if(res == 'overlay'){
            //     this.clearTime()
            //   }else{
            //     this.getCreateOrder(this.json)
            //   }
            // })
            if(this.timer == null){
              this.getTimerStatus(order_sn)
            }

          }
        })
      },
      getTimerStatus(order_sn){
        this.timer = setInterval(() => {
          if(this.count>=5){
            // this.create_status=1
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus(order_sn)
        },3*1000)
      },
      finishPay(order_sn){//支付完成
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = code
            this.clearTime()
            this.$router.push({path:'/success'})
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
        // location.href = 'https://api.youzuanmy.vip/xd/paysuccess.html?orderNo='+this.json.order_sn
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem('order_info')
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.finishPay()
            break;
          case 0:
            this.getCreateOrder(this.json)
            break;
        }
      },

    }
  }

</script>
<style scoped lang="less">
  @import "./css/tarot_activity.less";
</style>
